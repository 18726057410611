import React from 'react';
import { Box, Container, Heading, Text, VStack, Link, useBreakpointValue } from '@chakra-ui/react';

const FourthPage = () => {
  // Color mode hooks to handle dark mode
  const headingSize = useBreakpointValue({ base: 'lg', md: 'xl' });

  return (
    <Box id="fourth-section"
      bg="black" 
      color="white" 
      minH="100vh" 
      display="flex" 
      alignItems="center" 
      justifyContent="center" 
      p={4
    }>
      <Container 
        maxW={{ base: '90%', sm: '80%', md: '70%', lg: '50%' }} 
        centerContent
      >
        <VStack 
          spacing={6} 
          align="center" 
          p={6} 
          borderRadius="md" 
          bg="#D3D3D3" 
          boxShadow="md"
          width="100%" // Ensures container is responsive
        >
          <Heading as="h1" color="black" size={headingSize}>
            Contact Me
          </Heading>
          <Box textAlign="center">
            <Text fontSize={{ base: 'md', md: 'lg' }} color="black" mb={4}>
              Feel free to reach out to me via phone or email. I'm always happy to connect!
            </Text>
            <VStack spacing={4}>
              <Text fontSize={{ base: 'sm', md: 'md' }} color="black">
                <strong>WhatsApp:</strong> <Link href={`tel:+96171928240`} color="black">+961 71 928 240</Link>
              </Text>
              <Text fontSize={{ base: 'sm', md: 'md' }} color="black">
                <strong>Email:</strong> <Link href={`mailto:karlsamaha@hotmail.com`} color="black">karlsamaha@hotmail.com</Link>
              </Text>
            </VStack>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default FourthPage;
