import { useState, useEffect,useMemo,useRef} from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import FirstPage from "../components/FirstPage";
import SecondPage from '../components/SecondPage';
import ThirdPage from '../components/ThirdPage';
import FourthPage from '../components/FourthPage';



export  function Portfolio(){
/////////////////////////////////////////////////////////
  function smoothScrollTo(element, duration) {
    const targetPosition = element.getBoundingClientRect().top + window.scrollY;
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    let startTime = null;

    function animation(currentTime) {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = ease(timeElapsed, startPosition, distance, duration);
        window.scrollTo(0, run);
        if (timeElapsed < duration) requestAnimationFrame(animation);
    }

    function ease(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    }

    requestAnimationFrame(animation);
}

///////////////////////////////////////////////
  smoothscroll.polyfill();
var scrollCount = 0;
const sections = useMemo(() => [
  { id: 1, name: 'first-section' },
  { id: 2, name: 'second-section' },
  { id: 2, name: 'third-section' },
  { id: 2, name: 'fourth-section' },
  // more sections...
], []); 
var check = false;

const handleWheel = (event) => {
 if (check) {
        return; // Exit early if the event has already been handled
      }
      check = true;

    if (event.deltaY > 0 ) {
      if(scrollCount<3){
      scrollCount = scrollCount + 1;
       console.log(scrollCount);
    //  const element = document.getElementById(sections[scrollCount].name);
    //     element.scrollIntoView({ behavior: "smooth" }); 

    const element = document.getElementById(sections[scrollCount].name);
    smoothScrollTo(element, 1200);
  }
  setTimeout(() => {
    // This function will be called when scrolling stops
   check = false;
    // Place your callback or logic here
}, 1500); 
    }  else {
if(scrollCount >0){
      scrollCount = scrollCount - 1;
         console.log(scrollCount);
         const element = document.getElementById(sections[scrollCount].name);
         smoothScrollTo(element, 1200);
    }
    setTimeout(() => {
      // This function will be called when scrolling stops
    check = false;
      // Place your callback or logic here
  }, 1500); 
  }
    // Prevent default scrolling behavior
    event.preventDefault();
    // Update last scroll position
  };

////////////////////////////////////////////////////////////////////

  useEffect(() => {
    // Disable scrolling
    document.body.style.overflow = 'hidden';

    const element = document.getElementById('first-section');
    element.scrollIntoView({ behavior: "smooth" });
    scrollCount=0; 

    // Add event listeners
    document.addEventListener('wheel', handleWheel, { passive: false });

    
    return () => {
     
      document.addEventListener('wheel', handleWheel, { passive: false });
        // Optional: Remove touchend listener if added
        // document.removeEventListener('touchend', handleTouchEnd);
      };
   
  }, []);



  const [currentIndex, setCurrentIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const startYRef = useRef(null);

  useEffect(() => {
    const handleTouchStart = (e) => {
      startYRef.current = e.touches[0].clientY;
    };

    const handleTouchEnd = (e) => {
      if (isScrolling || startYRef.current === null) return;

      const endY = e.changedTouches[0].clientY;
      const diffY = startYRef.current - endY;

      if (Math.abs(diffY) > 50) {  // Threshold for swipe detection
        if (diffY > 0 && currentIndex < sections.length - 1) {
          // Swiped up - Move to the next section
          setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            scrollToSection(newIndex);
            return newIndex;
          });
        } else if (diffY < 0 && currentIndex > 0) {
          // Swiped down - Move to the previous section
          setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex - 1;
            scrollToSection(newIndex);
            return newIndex;
          });
        }
      }

      // Reset the starting Y coordinate
      startYRef.current = null;
    };

    const scrollToSection = (index) => {
      setIsScrolling(true);
      const sectionId = sections[index].name;
      const element = document.getElementById(sectionId);

      if (element) {
        smoothScrollTo(element, 1200);
        setTimeout(() => setIsScrolling(false), 1200);  // Match timeout to scroll animation duration
      }
    };

    // Add event listeners for touch events
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchend', handleTouchEnd);

    // Cleanup event listeners
    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [currentIndex, isScrolling, sections]);


return(
<>
<FirstPage/>
<SecondPage/>
<ThirdPage/>
<FourthPage/>
    </>
)
  }