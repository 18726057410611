import React from 'react';
import { Box, Image, Text, Flex, Icon ,Center} from '@chakra-ui/react';
import { IoIosArrowDown } from 'react-icons/io';
import LogoWithImage from '../assets/logo-with-title.png';
import { useState, useEffect} from 'react';

export default function FirstPage() {
  const [isSamsung, setIsSamsung] = useState(false);
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isSafariBrowser = userAgent.includes('Safari') && !userAgent.includes('Chrome');
    setIsSafari(isSafariBrowser);
    if (userAgent.includes('SamsungBrowser')) {
      setIsSamsung(true);
    }
  }, []);

  return (
<Box
  id="first-section"
  w="100vw"
  h="100vh"
  bg="black"
  display="flex"
  justifyContent="center"
  alignItems="center"
  position="relative"
  p={{ base: "4", md: "6", lg: "8" }}
>
  <Center
    w="100%"
    h="100%"
    padding={{ base: "10px", md: "20px", lg: "30px" }}
  >
    <Box textAlign="center" mt={{ base: "-10%", md: "0" }}>
      <Text
        fontSize={{ base: "2xl", md: "4xl", lg: "6xl" }} // Adjust the font size for different screen sizes
        color="white"
        fontFamily="'Verdana', sans-serif"
      >
        Karl Samaha
      </Text>
      <Text
        fontSize={{ base: "lg", md: "xl", lg: "2xl" }} // Adjust the font size for different screen sizes
        color="white"
        fontFamily="'Verdana', sans-serif"
        mt={{ base: "2", md: "4", lg: "6" }}
      >
        Freelance Software Engineer
      </Text>
    </Box>
  </Center>

  <Box
    position="absolute"
    bottom={{ base: isSamsung || isSafari ? '15%' : '9%', md: isSamsung || isSafari ? '9%' : '6%', lg: isSamsung || isSafari ? '9%' : '6%' }} 
    left={{ base: "5%", md: "8%", lg: "10%" }}
    color="white"
  >
    <Flex
      direction="row"
      align="center"
      fontSize={{ base: "sm", md: "md", lg: "lg" }}
    >
      <Text
        color="white"
        fontFamily="'Verdana', sans-serif"
        mr={{ base: "2", md: "4", lg: "6" }}
      >
        CONTINUE AS GUEST
      </Text>
      <Icon
        as={IoIosArrowDown}
        color="white"
        boxSize={{ base: "4", md: "5", lg: "6" }}
      />
    </Flex>
  </Box>

</Box>
  );
}