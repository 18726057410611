import { Box, Center, Table, Thead, Tbody, Tr, Th, Td, Text, Icon, Flex } from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import { FaReact, FaMobileAlt, FaJava, FaDatabase, FaCode } from "react-icons/fa";
import { useState, useEffect} from 'react';


export default function ThirdPage() {
    const [isSamsung, setIsSamsung] = useState(false);
    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
      const userAgent = navigator.userAgent;
      const isSafariBrowser = userAgent.includes('Safari') && !userAgent.includes('Chrome');
    setIsSafari(isSafariBrowser);
          if (userAgent.includes('SamsungBrowser')) {
            setIsSamsung(true);
          }
      
        // Clear the interval when component unmounts
       
      }, []);

    const skills = [
        { framework: 'React', description: 'Expert in building interactive UIs', icon: FaReact },
        { framework: 'Flutter', description: 'Experienced in cross-platform app development', icon: FaMobileAlt },
        { framework: 'Spring Boot', description: 'Proficient in creating backend services', icon: FaJava },
        { framework: 'Firebase', description: 'Skilled in real-time database and authentication', icon: FaDatabase },
        { framework: 'Supabase', description: 'Experienced in working with open-source alternatives', icon: FaDatabase },
        { framework: 'Chakra UI', description: 'Experienced in creating accessible and responsive UIs', icon: FaCode }
      ];
return (
 <Box
    id="third-section"
    w="100vw"
    h="100vh"
    bg="#D3D3D3"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    position="relative"
    p={{ base: "4", md: "6", lg: "8" }}
  >
    <Text
      fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
      mt={{ base: "10", md: "12", lg: "14" }} // Adjusted margin-top for the title
      mb={{ base: "4", md: "6", lg: "8" }}
      color="black"
      fontFamily="'Verdana', sans-serif"
      textAlign="center"
    >
      CORE SKILLS
    </Text>

    <Center
      w="100%"
      h="100%"
      padding={{ base: "4", md: "6", lg: "8" }} // Adjusted padding for better fit
      mt={{ base: "-20", md: "-16", lg: "-16" }} // Added margin-top to push the table up
    >
      <Box textAlign="center" maxW="90%">
        <Table variant="simple" colorScheme="blackAlpha" size={{ base: "sm", md: "md", lg: "lg" }}>
          <Thead>
            <Tr>
              <Th>Framework</Th>
              <Th>Description</Th>
            </Tr>
          </Thead>
          <Tbody>
            {skills.map((skill, index) => (
              <Tr key={index}>
                <Td>
                  <Flex align="center">
                    <Icon as={skill.icon} mr="2" boxSize={{ base: "4", md: "5", lg: "6" }} />
                    {skill.framework}
                  </Flex>
                </Td>
                <Td>{skill.description}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Center>

    <Box
    position="absolute"
    bottom={{ base: isSamsung || isSafari ? '15%' : '9%', md: isSamsung || isSafari ? '6%' : '6%', lg: isSamsung || isSafari ? '6%' : '6%'  }} 
    left={{ base: "5%", md: "7%", lg: "10%" }}
    color="white"
  >
      <Flex
        direction="row"
        align="center"
        fontSize={{ base: "sm", md: "md", lg: "lg" }}
      >
    <Text
        color="black"
        fontFamily="'Verdana', sans-serif"
        mr={{ base: "2", md: "4", lg: "6" }}
      >
        CONTINUE
      </Text>
      <Icon
        as={IoIosArrowDown}
        color="black"
        boxSize={{ base: "4", md: "5", lg: "6" }}
      />
      </Flex>
    </Box>
  </Box>
);


}